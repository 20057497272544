import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Container, styled, Toolbar, Box } from '@mui/material';
import { ReactComponent as Logo } from 'assets/images/logo.svg';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(8),
  padding: theme.spacing(1),
  justifyContent: 'center',

  [theme.breakpoints.up('sm')]: {
    height: theme.spacing(10),
  },
}));

const StyledLogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  '& svg': { height: 44, width: '100%' },

  [theme.breakpoints.up('sm')]: {
    '& svg': { height: 60 },
  },
}));

function Header() {
  const appBarRef = useRef(null);

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <StyledToolbar ref={appBarRef}>
            <StyledLogo component={Link} to={'/'}>
              <Logo />
            </StyledLogo>
          </StyledToolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;
