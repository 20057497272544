import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Layout from 'components/Layout';
import Page404 from 'pages/Page404';
import Home from 'pages/Home';

const Terms = lazy(() => import('pages/InfoPages/Terms'));
const Privacy = lazy(() => import('pages/InfoPages/Privacy'));
const Contact = lazy(() => import('pages/InfoPages/Contact'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Page404 />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'terms',
        element: <Terms />,
      },
      {
        path: 'privacy',
        element: <Privacy />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
    ],
  },
]);

export default router;
