import Hero from 'components/Hero';
import Block1 from 'components/Block1';
import Block2 from 'components/Block2';
import Block3 from 'components/Block3';
import Block4 from 'components/Block4';

function Home() {
  return (
    <>
      <Hero />
      <Block1 title="Why choose us?" />
      <Block2 title="Australian Hotel Escapes" />
      <Block3 title="About us" />
      <Block4 title="Get in touch" />
    </>
  );
}

export default Home;
