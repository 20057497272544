import { createTheme } from '@mui/material';

let getTheme = prefersDarkMode =>
  createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      ...(!prefersDarkMode
        ? {
            // palette values for light mode
            primary: { main: 'hsl(220, 18%, 48%)' },
            secondary: { main: 'hsl(24, 13%, 43%)' },
            error: {
              main: 'hsl(342.89, 66%, 40%)',
            },
            text: {
              secondary: 'hsl(26.65, 11%, 15%)',
            },
            background: {
              paper: 'hsl(84, 7%, 86%)',
            },
            gradient:
              'linear-gradient(0deg, rgba(219,221,216,1) 0%, rgba(255,255,255,1) 100%)',
          }
        : {
            // palette values for dark mode
            primary: { main: 'hsl(220, 18%, 52%)' },
            secondary: { main: 'hsl(24, 13%, 50%)' },
            text: {
              secondary: 'hsl(26.65, 11%, 60%)',
            },
            background: {
              paper: 'hsl(84, 7%, 23%)',
            },
            gradient:
              'linear-gradient(0deg, rgba(219,221,216,1) 0%, rgba(18,18,18,1) 99%)',
          }),
    },
    typography: {
      fontFamily: `"Mukta", sans-serif`,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 720,
        md: 900,
        lg: 1200,
        xl: 1600,
      },
    },
    components: {
      MuiStack: {
        defaultProps: {
          useFlexGap: true,
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            display: 'inline',
            margin: 0,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      },
    },
  });

export default getTheme;
