import { useFormik } from 'formik';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { enqueueSnackbar } from 'notistack';
import { useSnackbarAction } from 'hooks/useNotistackAction';
import { contactSchema } from 'helpers/validationSchema';

function ContactForm() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const action = useSnackbarAction();

  const { values, handleSubmit, handleChange, handleBlur, touched, errors } =
    useFormik({
      initialValues: {
        name: '',
        email: '',
        message: '',
        newsletter: false,
      },
      validationSchema: contactSchema,
      onSubmit: (values, actions) => {
        if (values) {
          enqueueSnackbar('Your request’s been sent!', {
            action,
            variant: 'success',
          });
        }
        actions.resetForm();
      },
    });

  return (
    <Grid container spacing={{ xs: 3, md: 5 }} justifyContent="center">
      <Grid xs={12} md={4} sx={{ display: 'flex' }}>
        <Stack
          spacing={{ xs: 1, md: 5 }}
          textAlign={isSmallScreen ? 'center' : 'left'}
        >
          <Typography
            color="secondary"
            component="h3"
            variant={isSmallScreen ? 'h6' : 'h4'}
          >
            Have questions or need assistance planning your next adventure?
          </Typography>
          <Typography
            component="p"
            variant={isSmallScreen ? 'subtitle1' : 'h6'}
          >
            Our dedicated team is here to help!
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={12} md={8}>
        <Box
          sx={{
            width: '90%',
            mx: 'auto',
          }}
        >
          <form onSubmit={handleSubmit}>
            <TextField
              hiddenLabel
              required
              fullWidth
              id="name"
              name="name"
              placeholder="Your name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name ? errors.name : ' '}
              InputLabelProps={{ shrink: true }}
              sx={{
                '& .MuiFormHelperText-root': {
                  textAlign: 'center',
                },
                input: {
                  '&::placeholder': {
                    opacity: 1,
                  },
                },
              }}
            />

            <TextField
              hiddenLabel
              fullWidth
              required
              id="email"
              name="email"
              placeholder="Your email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email ? errors.email : ' '}
              InputLabelProps={{ shrink: true }}
              sx={{
                '& .MuiFormHelperText-root': {
                  textAlign: 'center',
                },
                input: {
                  '&::placeholder': {
                    opacity: 1,
                  },
                },
              }}
            />

            <TextField
              multiline
              rows={3}
              hiddenLabel
              fullWidth
              required
              id="message"
              name="message"
              placeholder="Your message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.message && Boolean(errors.message)}
              helperText={
                touched.message && errors.message ? errors.message : ' '
              }
              InputLabelProps={{ shrink: true }}
              sx={{
                '& .MuiFormHelperText-root': {
                  textAlign: 'center',
                },
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.newsletter}
                  onChange={handleChange}
                  name="newsletter"
                />
              }
              label={
                <>
                  I accept the{' '}
                  <Link
                    component={NavLink}
                    to="/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms&Conditions
                  </Link>
                </>
              }
            />

            <Box>
              <Button
                fullWidth
                size={isSmallScreen ? 'medium' : 'large'}
                color="primary"
                variant="contained"
                type="submit"
                sx={{ mt: isSmallScreen ? 3 : 4 }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContactForm;
