import {
  useMediaQuery,
  Box,
  Paper,
  Typography,
  Container,
} from '@mui/material';

function Block3({ title }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box textAlign="center" sx={{ pb: { xs: 5, sm: 8 } }}>
      <Container>
        <Paper
          elevation={5}
          sx={{
            width: isSmallScreen ? '100%' : '80%',
            mx: 'auto',
            p: isSmallScreen ? 3 : 4,
            bgcolor: 'secondary.main',
          }}
        >
          <Typography
            gutterBottom
            variant={isSmallScreen ? 'h4' : 'h3'}
            component="h2"
            color="background.default"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {title}
          </Typography>
          <Typography
            gutterBottom
            variant={isSmallScreen ? 'subtitle1' : 'h6'}
            component="p"
            color="background.paper"
            sx={{
              fontWeight: 400,
            }}
          >
            For anybody looking to discover the most renowned casinos and poker
            rooms in Australia, we are a must-have web resource. This website
            offers thorough evaluations, rankings, and side-by-side analyses of
            a variety of opulent casino resorts, showcasing their distinctive
            gaming settings, luxurious lodging options, and top-notch services.
            We assist you in finding the ideal fit, whether your plans call for
            a laid-back vacation on the Gold Coast or a sophisticated city break
            in Sydney. It provides details on each hotel's food choices,
            entertainment programs, and guest amenities, making it a veritable
            gold mine of knowledge for both infrequent travelers and ardent
            players.
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
}

export default Block3;
