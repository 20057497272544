import { IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

function useSnackbarAction() {
  const { closeSnackbar } = useSnackbar();
  return snackbarId => (
    <>
      <IconButton
        aria-label="close toast"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CloseOutlined />
      </IconButton>
    </>
  );
}

export { useSnackbarAction };
