import { useState } from 'react';
import { NavLink, MemoryRouter } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  useMediaQuery,
  IconButton,
  Button,
  Typography,
  Box,
  Backdrop,
  Checkbox,
  Fade,
  FormControlLabel,
  Link,
  Modal,
  styled,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { signUpSchema } from 'helpers/validationSchema';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styles from './SignUpModal.module.scss';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90dvw',
  maxWidth: 500,
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[22],
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '1%',
  right: '1%',
  padding: theme.spacing(0.5),
  borderRadius: 1,
}));

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  '& input': {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

function Router(props) {
  const { children } = props;
  return <MemoryRouter>{children}</MemoryRouter>;
}

function SignUpForm({ onSubmit }) {
  const [phone, setPhone] = useState('');
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      newsletter: false,
      accept: false,
    },
    validationSchema: signUpSchema,
    onSubmit: (values, actions) => {
      onSubmit(values);
      actions.resetForm();
    },
  });

  const handlePhoneChange = value => {
    setPhone(value);
    setFieldValue('phone', value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        variant="standard"
        color="secondary"
        required
        fullWidth
        id="name"
        name="name"
        label="Name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name ? errors.name : ' '}
        sx={{
          '& .MuiFormHelperText-root': {
            textAlign: 'center',
          },
        }}
      />
      <TextField
        variant="standard"
        color="secondary"
        required
        fullWidth
        id="email"
        name="email"
        label="Email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email ? errors.email : ' '}
        sx={{
          '& .MuiFormHelperText-root': {
            textAlign: 'center',
          },
        }}
      />
      <StyledPhoneInput
        containerClass={styles.phoneContainer}
        inputClass={styles.phoneInput}
        specialLabel=""
        country={'au'}
        value={phone}
        onChange={handlePhoneChange}
        inputProps={{
          name: 'phone',
          // required: true,
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={values.newsletter}
            onChange={handleChange}
            name="newsletter"
          />
        }
        label="I want to have exclusive deals sent to my phone and email"
        sx={{ mt: 2, mr: 0 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            required
            checked={values.accept}
            onChange={handleChange}
            name="accept"
          />
        }
        label={
          <Router>
            I accept the{' '}
            <Link
              component={NavLink}
              to="/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms&Conditions
            </Link>
          </Router>
        }
        sx={{ mt: 1, mr: 0 }}
      />
      <Button
        fullWidth
        size={isSmallScreen ? 'medium' : 'large'}
        variant="contained"
        type="submit"
        sx={{ mt: 2 }}
      >
        Submit
      </Button>
    </form>
  );
}

function ThankYouModal({ open, onClose }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="thank-you-modal-title"
      aria-describedby="thank-you-modal-description"
      sx={{ '& .MuiBox-root': { maxWidth: 400 } }}
    >
      <StyledBox>
        <Typography
          align="center"
          variant="h6"
          component="h3"
          id="thank-you-modal-title"
          sx={{ mb: 2 }}
        >
          Thanks you for joining us!
        </Typography>
        <Typography
          align="center"
          variant="h6"
          component="h3"
          id="thank-you-modal-description"
          sx={{ mb: 2 }}
        >
          Our team will contact you soon
        </Typography>
        {/* Add timer here for auto-closing */}
        <Button fullWidth variant="contained" onClick={onClose} sx={{ mt: 2 }}>
          Close
        </Button>
      </StyledBox>
    </Modal>
  );
}

function SignUpModal({ openForm, setOpenForm }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [thankYouOpen, setThankYouOpen] = useState(false);

  const handleCloseThankYou = () => {
    setThankYouOpen(false);
    setOpenForm(false);
  };

  const handleSubmit = formData => {
    if (formData) {
      setThankYouOpen(true);
    } else {
      setOpenForm(false);
    }
  };

  return (
    <>
      <Modal
        keepMounted
        aria-labelledby="signup-modal-title"
        open={openForm}
        onClose={() => setOpenForm(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 300,
          },
        }}
        sx={{
          '& .MuiBox-root': {
            maxWidth: 600,
            py: 5,
            px: isSmallScreen ? 6 : 7,
          },
        }}
      >
        <Fade in={openForm}>
          <StyledBox>
            <Typography
              align="center"
              variant="h6"
              component="h2"
              id="signup-modal-title"
              sx={{ mb: 3 }}
            >
              JOIN NOW TO RECEIVE ACTUAL CASINO PROMOTIONS
            </Typography>
            <StyledIconButton
              aria-label="close"
              onClick={() => setOpenForm(false)}
            >
              <Close fontSize="large" color="primary" />
            </StyledIconButton>
            <SignUpForm onSubmit={handleSubmit} />
          </StyledBox>
        </Fade>
      </Modal>

      <ThankYouModal open={thankYouOpen} onClose={handleCloseThankYou} />
    </>
  );
}

export default SignUpModal;
