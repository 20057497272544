import { Link } from 'react-router-dom';
import {
  useMediaQuery,
  Box,
  Button,
  Typography,
  Container,
  Paper,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Circle } from '@mui/icons-material';
import hotels from 'data/hotels.json';

function Block2({ title }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box textAlign="center" sx={{ pb: { xs: 5, sm: 8 } }}>
      <Container>
        <Typography
          gutterBottom
          variant={isSmallScreen ? 'h4' : 'h3'}
          component="h2"
          color="primary"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {title}
        </Typography>
        <Typography
          gutterBottom
          variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
          component="p"
          color="text.secondary"
          sx={{
            maxWidth: '90%',
            mx: 'auto',
            mb: isSmallScreen ? 4 : 5,
          }}
        >
          Discover the epitome of luxury and comfort with our curated selection
          of premier hotels across Australia. From the vibrant cityscapes of
          Melbourne and Sydney to the tranquil shores of Queensland and beyond,
          each hotel offers a unique blend of elegance and sophistication.
          Immerse yourself in unparalleled hospitality, exquisite amenities, and
          breathtaking surroundings as you embark on an unforgettable journey
          through the diverse landscapes of Australia. Explore our handpicked
          collection and elevate your travel experience with our exclusive
          Australian Hotel Escapes.
        </Typography>
        <Box>
          {hotels.map(item => (
            <Box key={item.id}>
              <Divider component="div" />
              <Grid container spacing={4} sx={{ py: 3 }}>
                <Grid
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: isSmallScreen ? 'center' : 'left',
                  }}
                >
                  <Typography
                    gutterBottom
                    variant={isSmallScreen ? 'h6' : 'h6'}
                    component="h4"
                    color="primary"
                  >
                    {item.title}
                  </Typography>
                  <Typography variant={isSmallScreen ? 'body2' : 'body1'}>
                    {item.desc}
                  </Typography>
                </Grid>

                <Grid xs={12} md={6}>
                  <Paper
                    elevation={1}
                    key={item.id}
                    color="background.default"
                    sx={{ p: 2, flexGrow: 1 }}
                  >
                    <Grid container spacing={2}>
                      <Grid xs={12} lg={6}>
                        <Box
                          component="img"
                          src={item?.image}
                          alt={item?.title}
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        lg={6}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'space-evenly',
                          gap: 1,
                        }}
                      >
                        <List disablePadding>
                          <ListItem>
                            <ListItemText
                              primary="Location:"
                              primaryTypographyProps={{
                                fontWeight: '700',
                                textAlign: 'center',
                              }}
                              secondary={
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  spacing={1}
                                  sx={{ mt: 1 }}
                                >
                                  <Circle
                                    sx={{ fontSize: 8 }}
                                    color="disabled"
                                  />

                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    {item.location}
                                  </Typography>
                                </Stack>
                              }
                              secondaryTypographyProps={{
                                component: 'div',
                              }}
                            />
                          </ListItem>
                        </List>

                        <Button
                          component={Link}
                          to={item?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          size={isSmallScreen ? 'medium' : 'large'}
                          color="primary"
                          variant="contained"
                          sx={{
                            width: isSmallScreen ? '100%' : 'auto',
                            textTransform: 'capitalize',
                          }}
                        >
                          Show more
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}

export default Block2;
