import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import Header from 'components/Header';
import Footer from 'components/Footer';
import HelmetHead from './HelmetHead';

const Layout = () => {
  return (
    <>
      <HelmetHead />
      <Header />
      <main>
        <Suspense
          fallback={
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <CircularProgress size={100} />
            </Box>
          }
        >
          <Outlet />
        </Suspense>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
