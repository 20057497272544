import { Link, useRouteError } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ArrowBackOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

const Page404 = () => {
  const error = useRouteError();

  return (
    <>
      <Helmet>
        <title>404 Not Found</title>
      </Helmet>
      <main>
        <Box
          textAlign="center"
          sx={{
            minHeight: '100dvh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 3,
            p: 2,
          }}
        >
          <Typography variant="h2">
            {error.status === '404' || 404 ? '404' : ''}
          </Typography>
          <Typography variant="h5">
            The page you’re looking for doesn’t exist.
          </Typography>
          <Button
            startIcon={<ArrowBackOutlined />}
            component={Link}
            to="/"
            size="large"
            color="primary"
          >
            back home
          </Button>
        </Box>
      </main>
    </>
  );
};

export default Page404;
