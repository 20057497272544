import {
  Box,
  Button,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import heroImg from 'assets/images/hero.webp';

function Hero() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        height: { xs: '50dvh', sm: '80dvh' },
        display: 'flex',
        justifyContent: 'center',
        p: 2,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundImage: `url(${heroImg})`,
        bgcolor: 'background.default',
      }}
    >
      <Stack justifyContent="center" alignItems="center" textAlign="center">
        <Typography
          gutterBottom
          component="h1"
          variant={isSmallScreen ? 'h4' : 'h2'}
          color="hsl(223.81 -173% 100%)"
          sx={{
            textTransform: 'uppercase',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          Explore, Dream, Discover
        </Typography>
        <Typography
          component="p"
          variant={isSmallScreen ? 'h6' : 'h5'}
          color="hsl(84, 7%, 86%)"
          sx={{
            maxWidth: 800,
            mb: 3,
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          Immerse yourself in a world of opulence and excitement as you explore
          Australia's finest casino hotels and captivating destinations
        </Typography>
        <Box textAlign="center">
          <Button
            component={Link}
            href="#block1"
            size={isSmallScreen ? 'medium' : 'large'}
            color="primary"
            variant="contained"
            sx={{
              mt: isSmallScreen ? 2 : 3,
              fontSize: isSmallScreen ? '1.1rem' : '1.4rem',
              textTransform: 'capitalize',
              fontWeight: 400,
            }}
          >
            Show more
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default Hero;
