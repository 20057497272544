import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const noIndexPages = ['/terms', '/privacy', '/contact'];

const TITLE = 'BestAussieList.com';
const keywords = 'casino, casinos, top, list';

function HelmetHead() {
  const location = useLocation();
  const isNoIndexPage = noIndexPages.includes(location.pathname);

  return (
    <Helmet>
      <title>{TITLE}</title>
      <meta name="keywords" content={keywords} />
      {isNoIndexPage && <meta name="robots" content="noindex" />}
    </Helmet>
  );
}

export default HelmetHead;
