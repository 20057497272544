import { object, string, boolean } from 'yup';

const baseSchema = object({
  name: string('Enter your name')
    .min(2, 'Name is too short')
    .matches(/^[a-zA-ZА-ЩЬЮЯҐЄІЇа-щьюяґєії'\s]+$/, 'Only alphabets are allowed')
    .required('Name is required'),
  email: string('Enter your email')
    .email('Enter a valid email')
    .matches(
      // eslint-disable-next-line no-control-regex
      /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*(\.\w{2,})+$/,
      'Incorrect format'
    )
    .required('Email is required'),
});

const signUpSchema = baseSchema.shape({
  phone: string('Enter your phone number').matches(
    /^[0-9\s()+-]*$/,
    'Invalid phone number format'
  ),
  newsletter: boolean(),
  accept: boolean().required('This checkbox is required'),
});

const contactSchema = baseSchema.shape({
  message: string('Enter your message')
    .min(3, 'Text is too short')
    .required('This field is required'),
});

export { signUpSchema, contactSchema };
