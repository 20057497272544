import { useMediaQuery, Box, Typography, Container } from '@mui/material';
import ContactForm from './ContactForm';

function Block4({ title }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box textAlign="center" sx={{ pb: { xs: 5, sm: 8 } }}>
      <Container>
        <Typography
          variant={isSmallScreen ? 'h4' : 'h3'}
          component="h2"
          sx={{
            mb: isSmallScreen ? 2 : 5,
            textTransform: 'capitalize',
          }}
        >
          {title}
        </Typography>

        <ContactForm />
      </Container>
    </Box>
  );
}

export default Block4;
