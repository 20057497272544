import {
  useMediaQuery,
  Box,
  Typography,
  Container,
  Paper,
  Avatar,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  CheckCircleOutlineOutlined,
  InfoOutlined,
  StarBorderOutlined,
  HandshakeOutlined,
  ConnectWithoutContactOutlined,
} from '@mui/icons-material';

const items = [
  {
    id: 1,
    icon: <CheckCircleOutlineOutlined />,
    title: 'Expertise & Curation',
    desc: 'We handpick the finest casino hotels and destinations across Australia to ensure that every aspect of your journey is nothing short of extraordinary.',
  },
  {
    id: 2,
    icon: <InfoOutlined />,
    title: 'Personalized Service',
    desc: "From the moment you reach out to us, you'll experience attentive and dedicated assistance tailored to your unique preferences and needs.",
  },
  {
    id: 3,
    icon: <StarBorderOutlined />,
    title: 'Unparalleled Quality',
    desc: 'From luxury accommodations to thrilling gaming experiences, we partner with only the best to ensure that your adventure exceeds expectations.',
  },
  {
    id: 4,
    icon: <HandshakeOutlined />,
    title: 'Transparency & Trust',
    desc: 'You can rely on us to provide honest and reliable information, transparent pricing, and clear communication every step of the way.',
  },
  {
    id: 5,
    icon: <ConnectWithoutContactOutlined />,
    title: 'Community & Connection',
    desc: 'Join us on social media and stay connected with like-minded individuals as you embark on your journey.',
  },
];

function Block1({ title }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Box textAlign="center" sx={{ py: { xs: 5, sm: 8 } }} id="block1">
      <Container>
        <Typography
          gutterBottom
          variant={isSmallScreen ? 'h4' : 'h3'}
          component="h2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {title}
        </Typography>
        <Typography
          gutterBottom
          variant={isSmallScreen ? 'subtitle1' : 'h6'}
          component="h3"
          color="secondary"
          sx={{
            maxWidth: isSmallScreen ? '90%' : '80%',
            mx: 'auto',
            mb: isSmallScreen ? 4 : 5,
          }}
        >
          At BestAussieList.com, we understand that you have many options when
          it comes to planning your travel adventures. So why choose us? Here
          are just a few reasons:
        </Typography>
        <Grid container spacing={{ xs: 3, md: 5 }} justifyContent="center">
          {items.map(item => (
            <Grid
              xs={12}
              sm={4}
              key={item.id}
              sx={{ display: 'flex', flexGrow: 1 }}
            >
              <Paper elevation={4} key={item.id} sx={{ p: 3, flexGrow: 1 }}>
                <Avatar sx={{ mx: 'auto', mb: 2 }}>{item.icon}</Avatar>
                <Typography
                  gutterBottom
                  variant={isSmallScreen ? 'h6' : 'h6'}
                  component="h4"
                >
                  {item.title}
                </Typography>
                <Typography variant={isSmallScreen ? 'body2' : 'body1'}>
                  {item.desc}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default Block1;
