import { useEffect, useMemo, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import './styles/global.scss';
import getTheme from 'theme';
import Layout from 'components/Layout';
import router from 'routerConfig';
import SignUpModal from 'components/SignUpModal/SignUpModal';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(() => getTheme(prefersDarkMode), [prefersDarkMode]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <SnackbarProvider
      maxSnack={2}
      iconVariant={{
        success: '✅',
        error: '✖️',
        warning: '⚠️',
        info: 'ℹ️',
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {showModal && (
          <SignUpModal openForm={true} setOpenForm={setShowModal} />
        )}
        <RouterProvider router={router}>
          <Layout />
        </RouterProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
