import { NavLink } from 'react-router-dom';
import {
  Box,
  Link,
  List,
  ListItem,
  styled,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  boxShadow: theme.shadows[1],
  background: theme.palette.gradient,

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  justifyContent: 'center',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: 'inherit',
  transition: 'color 0.3s',
  textDecoration: 'none',
  textTransform: 'uppercase',

  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

function Footer() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <StyledBox>
      <Container>
        <List
          dense
          disablePadding
          sx={{
            display: !isSmallScreen ? 'flex' : 'block',
            gap: !isSmallScreen ? 1 : '',
          }}
        >
          <StyledListItem>
            <StyledLink component={NavLink} to="/terms">
              Terms&Conditions
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink component={NavLink} to="/privacy">
              Privacy Policy
            </StyledLink>
          </StyledListItem>
          <StyledListItem>
            <StyledLink component={NavLink} to="/contact">
              Contact us
            </StyledLink>
          </StyledListItem>
        </List>

        <Box textAlign="center" sx={{ mt: { xs: 1, sm: 3, lg: 4 } }}>
          <Typography variant="body2" component="span">
            All rights reserved &copy;{' '}
            <Link
              component={NavLink}
              to="/"
              color="inherit"
              underline="none"
              variant="body2"
            >
              BestAussieList.com
            </Link>
            2024
          </Typography>
        </Box>
      </Container>
    </StyledBox>
  );
}

export default Footer;
